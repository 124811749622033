import React from "react";

const Footer = () => {
  return (
    <div className="--flex-center --py2">
      <p>Tous les droits sont réservés. &copy; 2023</p>
    </div>
  );
};

export default Footer;